<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2 v-if="protocol.bpe">Versions et validations</h2>
      <h2 v-else>Versions et validation</h2>
    </Container>
  </div>

  <template v-if="protocol.bpe">
    <Container>
      <h3 class="margin-top">Retranscription</h3>
    </Container>
    <div class="key-values">
      <KeyValue table
        label="Nom"
        :value="
          getValidation('RETRANSCRIPTION')?.utilisateur?.prenom &&
            getValidation('RETRANSCRIPTION')?.utilisateur?.nom
            ? getValidation('RETRANSCRIPTION')?.utilisateur?.prenom +
            ' ' +
            getValidation('RETRANSCRIPTION')?.utilisateur?.nom
            : null
        " />
      <KeyValue table
        label="Date"
        :value="
          getValidation('RETRANSCRIPTION')?.date
            ? new Date(
              getValidation('RETRANSCRIPTION')?.date
            ).toLocaleString()
            : null
        " />
      <KeyValue table
        label="Signature (trigramme)"
        :value="getValidation('RETRANSCRIPTION')?.trigramme" />
      <KeyValue table
        label="Version"
        :value="
          getValidation('RETRANSCRIPTION')
            ? protocol?.version.version
            : 'En attente de validation'
        " />
    </div>
    <Container>
      <div class="v-margin text-align-right"
        v-if="!getValidation('RETRANSCRIPTION')">
        <Btn text="Valider"
          color="primary"
          @click="
            openModal('acceptVersion', {
              id: $route.params.id,
              type: 'RETRANSCRIPTION',
            })
          " />
      </div>
    </Container>

    <Container>
      <h3 class="margin-top">Vérification</h3>
    </Container>
    <div class="key-values">
      <KeyValue table
        label="Nom"
        :value="
          getValidation('VERIFICATION')?.utilisateur?.prenom &&
            getValidation('VERIFICATION')?.utilisateur?.nom
            ? getValidation('VERIFICATION')?.utilisateur?.prenom +
            ' ' +
            getValidation('VERIFICATION')?.utilisateur?.nom
            : null
        " />
      <KeyValue table
        label="Date"
        :value="
          getValidation('VERIFICATION')?.date
            ? new Date(getValidation('VERIFICATION')?.date).toLocaleString()
            : null
        " />
      <KeyValue table
        label="Signature (trigramme)"
        :value="getValidation('VERIFICATION')?.trigramme" />
      <KeyValue table
        label="Version"
        :value="
          getValidation('VERIFICATION')
            ? protocol?.version.version
            : 'En attente de validation'
        " />
    </div>
    <Container>
      <div class="v-margin text-align-right"
        v-if="!getValidation('VERIFICATION')">
        <Btn text="Valider"
          color="primary"
          @click="
            openModal('acceptVersion', {
              id: $route.params.id,
              type: 'VERIFICATION',
            })
          " />
      </div>
    </Container>

    <Container>
      <h3 class="margin-top">Validation de l'expérimentateur</h3>
    </Container>
    <div class="key-values">
      <KeyValue table
        label="Nom"
        :value="
          getValidation('VALIDATIONEXPERI')?.utilisateur?.prenom &&
            getValidation('VALIDATIONEXPERI')?.utilisateur?.nom
            ? getValidation('VALIDATIONEXPERI')?.utilisateur?.prenom +
            ' ' +
            getValidation('VALIDATIONEXPERI')?.utilisateur?.nom
            : null
        " />
      <KeyValue table
        label="Date"
        :value="
          getValidation('VALIDATIONEXPERI')?.date
            ? new Date(
              getValidation('VALIDATIONEXPERI')?.date
            ).toLocaleString()
            : null
        " />
      <KeyValue table
        label="Signature (trigramme)"
        :value="getValidation('VALIDATIONEXPERI')?.trigramme" />
      <KeyValue table
        label="Version"
        :value="
          getValidation('VALIDATIONEXPERI')
            ? protocol?.version.version
            : 'En attente de validation'
        " />
    </div>
    <Container>
      <div class="v-margin text-align-right"
        v-if="!getValidation('VALIDATIONEXPERI')">
        <Btn text="Valider"
          color="primary"
          @click="
            openModal('acceptVersion', {
              id: $route.params.id,
              type: 'VALIDATIONEXPERI',
            })
          " />
      </div>
    </Container>

    <Container>
      <h3 class="margin-top">Validation du responsable</h3>
    </Container>
    <div class="key-values">
      <KeyValue table
        label="Nom"
        :value="
          getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.prenom &&
            getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.nom
            ? getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.prenom +
            ' ' +
            getValidation('VALIDATIONRESPONSABLE')?.utilisateur?.nom
            : null
        " />
      <KeyValue table
        label="Date"
        :value="
          getValidation('VALIDATIONRESPONSABLE')?.date
            ? new Date(
              getValidation('VALIDATIONRESPONSABLE')?.date
            ).toLocaleString()
            : null
        " />
      <KeyValue table
        label="Signature (trigramme)"
        :value="getValidation('VALIDATIONRESPONSABLE')?.trigramme" />
      <KeyValue table
        label="Version"
        :value="
          getValidation('VALIDATIONRESPONSABLE')
            ? protocol?.version.version
            : 'En attente de validation'
        " />
    </div>
    <Container>
      <div class="v-margin text-align-right"
        v-if="!getValidation('VALIDATIONRESPONSABLE')">
        <Btn text="Valider"
          color="primary"
          @click="
            openModal('acceptVersion', {
              id: $route.params.id,
              type: 'VALIDATIONRESPONSABLE',
            })
          " />
      </div>
    </Container>

    <template v-if="protocol.prestation_service">
      <Container>
        <h3 class="margin-top">Validation du donneur d'ordre</h3>
      </Container>
      <div class="key-values">
        <KeyValue table
          label="Validation donneur d'ordre"
          :value="
            getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.prenom &&
              getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.nom
              ? getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.prenom +
              ' ' +
              getValidation('VALIDATIONDONNEURORDRE')?.utilisateur?.nom
              : null
          " />
        <KeyValue table
          label="Date"
          :value="
            getValidation('VALIDATIONDONNEURORDRE')?.date
              ? new Date(
                getValidation('VALIDATIONDONNEURORDRE')?.date
              ).toLocaleString()
              : null
          " />
        <KeyValue table
          label="Signature (trigramme)"
          :value="getValidation('VALIDATIONDONNEURORDRE')?.trigramme" />
      </div>
      <Container>
        <div class="v-margin text-align-right"
          v-if="!getValidation('VALIDATIONDONNEURORDRE')">
          <Btn text="Valider"
            color="primary"
            @click="
              openModal('acceptVersion', {
                id: $route.params.id,
                type: 'VALIDATIONDONNEURORDRE',
              })
            " />
        </div>
      </Container>
    </template>
  </template>
  <template v-else>
    <Container class="margin-bottom">
      <p class="margin-bottom max-width-sm">Votre protocole est prêt à être publié,
        vous pouvez l'enregistrer comme brouillon pour le conserver ou directement le valider.</p>

      <Btn v-if="protocol?.statut?.uid !== 'VALIDE'
    && (helperService.userHasPermission('protocole_statusvalidate')
      && !protocol?.bpe)"
        @click="manualValidation()"
        btnType="submit"
        text="Valider le protocole"
        icon="check"
        color="primary" />

      <Btn v-if="protocol?.statut?.uid === 'VALIDE' && false"
        btnType="submit"
        @click="manualDraft()"
        text="Enregistrer le protocole comme brouillon"
        color="default" />

      &nbsp;

      <Btn v-if="protocol?.statut?.uid === 'VALIDE'"
        disabled
        btnType="submit"
        text="Protocole validé"
        icon="check"
        color="primary" />
    </Container>

  </template>

  <!-- Modals modalAcceptVersion-->
  <Modal v-show="modal.acceptVersion"
    :title="`Validation ${modalData.type}`"
    :key="componentKey"
    :active="modal.acceptVersion"
    :data="modalData"
    @modal-close="modal.acceptVersion = false"
    :containForm="true"
    :modalValidationSchema="validationSchema[7]"
    @modal-form-submitted="acceptVersion($event, modalData)">
    <template v-slot:modal-body>
      <Input id="trigramme"
        required
        :options="{ minlength: 3, maxlength: 3 }"
        label="Signature (trigramme)" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler"
        @click="modal.acceptVersion = false" />
      <Btn btnType="submit"
        text="Valider"
        color="primary" />
    </template>
  </Modal>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'
import Input from '@/components/form/Input.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'VersionsAndValidationAddEditSubview',

  components: {
    Input,
    Modal,
    Btn,
    KeyValue,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['set-step', 'populate', 'set-route'],

  data(props) {
    const vs = props.validationSchema[7]
    const { isSubmitting, errors } = this.formService.initFrom(vs)

    return {
      vs,
      isSubmitting,
      errors,
      type_uid: '',
      protocol: {},
      step: 8,
      routeName: this.$route.name,
      previousRouteName: null,
      modalData: {},
      modal: {
        acceptVersion: false,
      },
      obsEndpoint: `protocole/${this.$route.params.id}/modalite_observation`,
      method: 'post',
      obsId: null,
      modalCreateObservationTitle: "Création d'une observation",
      componentKey: 0,
      validations: [],
      file: {},
    }
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolRatingCalendarEdit'
    } else {
      this.previousRouteName = 'protocolRatingCalendarAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: null,
      routeParams,
    })
    this.protocol = await this.getProtocol()
    this.getValidations()

    this.emitter.emit('close-loader')
  },

  watch: {
    'modal.acceptVersion': {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.getValidations()
          this.$forceUpdate()
        })
      },
    },
  },

  methods: {
    openModal(modal, data = {}) {
      this.modalData = data
      this.modal[modal] = true
    },

    setMedia(event) {
      this.file = event
    },

    async getProtocol() {
      const response = await this.fetchService.get(
        `protocole/${this.$route.params.id}`,
      )
      const protocol = await response.data
      this.$emit('populate', protocol)

      return protocol
    },

    acceptVersion(event, data) {
      const lastVersion = this.protocol.version
      this.fetchService
        .post(
          `protocole/${this.$route.params.id}/version/${lastVersion.id}/validation`,
          {
            trigramme: event.trigramme,
            type_uid: data.type,
          },
        )
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'La version a bien été validée.',
          })
        })

      this.modal.acceptVersion = false
    },

    getValidations() {
      this.fetchService
        .get(`protocole/${this.$route.params.id}`)
        .then((protocol) => {
          this.fetchService
            .get(
              `protocole/${this.$route.params.id}/version/${protocol.data.version.id}/validation`,
            )
            .then((response) => {
              this.validations = response.data
            })
        })
    },

    getValidation(type) {
      if (this.validations) {
        const validation = this.validations.filter((v) => v.type.uid === type)

        if (validation.length > 0) {
          return validation[0]
        }
        return null
      }
      return null
    },

    manualValidation() {
      this.fetchService
        .patch(`protocole/${this.$route.params.id}/valider`)
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le protocole a bien été validé.',
          })
          this.$router.push({ name: 'protocols' })
        })
    },
    manualDraft() {
      this.fetchService
        .put(`protocole/${this.$route.params.id}`, {
          statut_uid: 'BROUILLON',
        })
        .then(() => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le protocole a bien été enregistré comme brouillon.',
          })
          this.$router.push({ name: 'protocols' })
        })
    },

  },
}
</script>

<style scoped>

</style>
